import React from "react";
import '../App.css';

function Haslo_reklamowe() {
    return (
        <div style={{ 
            width: '100%', // Ustawienie szerokości na pełną szerokość elementu nadrzędnego
            padding: 0,    // Usunięcie paddingu
            margin: 0,     // Usunięcie marginesów
            overflowX: 'hidden', // Ukrycie wszelkiego nadmiaru treści w osi X
            background: 'linear-gradient(145deg, rgba(15, 15, 15, 1) 0%, rgba(30, 30, 30, 1) 30%, rgba(50, 50, 50, 1) 50%, rgba(30, 30, 30, 1) 70%, rgba(15, 15, 15, 1) 100%)',
        }}>
            <div className="row"> {/* Zastosowanie klasy row */}
                <div className="col-12 mt-4 align-items-center text-center">
                    <h1>APARTAMENTY NA SPRZEDAŻ</h1>
                    <p id="baner">W DOSKONAŁEJ LOKALIZACJI!</p>
                </div>
            </div>
        </div>
    );
}

export default Haslo_reklamowe;
