import zdj1 from './zdjecia/Świnoujście_z_lotu_ptaka.jpg';


function SwinoujscieOpis(){
    return (
        <div className='row d-flex justify-content-center align-items-center' id='swinoujscie_opis'>
            <div className='col-md-6'>
                <img src={zdj1} alt='Świnoujście z lotu ptaka' title='Świnoujście z lotu ptaka' className='img-fluid' style={{ 
                    width: '100%', 
                    height: 'auto', 
                    borderRadius: '20px',
                    objectFit: 'cover',
                }}
                    ></img>
            </div>
            <div className='col-md-6'>
                <h3 className='mb-3 mt-4 mt-md-0'>Świnoujście</h3>
                <p>Świnoujście, perła polskiego wybrzeża, to miasto o wyjątkowym uroku. Położone na kilkudziesięciu wyspach tworzy niepowtarzalny, nadmorski klimat. Znane uzdrowisko i popularna destynacja turystyczna przyciąga zarówno miłośników relaksu, jak i aktywnego wypoczynku. Szerokie, piasczyste plaże, malownicze krajobrazy oraz świeże, morskie powietrze sprawiają, że każdy znajdzie tu swoje miejsce.</p>
                <p>Dzięki nowoczesnej infrastrukturze - terminal promowy z połączeniami do Szwecji i Danii, tunel łączący wyspy Uznam i Wolin oraz komfortowy dojazd drogą S3 - Świnoujście jest łatwo dostępne dla turystów z Polski i Europy. To idealna baza wypadowa na odkrywanie piękna Bałtyku i kolicznyc wysp. Jeśli szukasz miejsca, gdzie natura łączy się z nowoczesnością, a wypoczynek z aktywnością - Świnoujście to wybór doskonały.</p>
            </div>
        </div>
    );
}

export default SwinoujscieOpis;