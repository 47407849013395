import React from "react";
import logo from './zdjecia/logo.png';
import ikona from './zdjecia/ikona_facebook.png';

function Footer() {
    return (
        <div className="container-fluid" id='footer'>
            <div className="row justify-content-around align-items-center">
                <div className="col-md-4 col-sm-6 col-12 mb-4 text-md-left text-center">
                    <img src={logo} alt='logo' className='img-fluid'  style={{maxWidth: '150px'}}></img>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-4 text-center">
                    <p>Wszystkie prawa zastrzeżone dla ® ARKADA - INWEST Sp. z o.o.</p>
                </div>
                <div className="col-md-4 col-sm-6 col-12 text-center">
                    <a href="https://www.facebook.com/PbCiroko/?locale=pl_PL" target="blank" rel="noopener noreferrer">
                    <img src={ikona} alt="facebook" className="img-fluid" style={{maxWidth: '45px', height:'auto'}}></img>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
