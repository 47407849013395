import React from 'react';
import img1 from './zdjecia/zagospodarowanie_terenu.jpg';


function Opis () {
    return (
        <div className='container-lg' id='opis_inwestycji'>
            <div className='row d-flex align-items-center'>
                <div className='col-12 col-md-6 d-flex'>
                    <a href={img1} target='_blank' rel="noopener noreferrer">
                    <img src={img1} alt='opis' title='Zagospodarowanie terenu' className='img-fluid' style={{ 
                        borderRadius: '20px',
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                    }}
                        ></img>
                    </a>
                </div>
                <div className='col-md-6 d-sm-flex flex-column'>
                    <h3 className='mb-4 mt-5'>Nowoczesna architektura w harmonii z naturą</h3>
                    <p>Zdrojowa Park to kompleks czterech nowoczesnych, ośmiopiętrowych budynków mieszkalnych w Świnoujściu, oferujący luksusowe apartamenty w wyjątkowej lokalizacji, w bezpośrednim sąsiedztwie malowniczego Parku Zdrojowego. Szczegółowo przemyślane przez nas zagospodarowanie terenu zapewnia bogactwo zieleni, tworząc harmonijną przestrzeń do życia i wypoczynku. Zaledwie 450 metrów dzieli mieszkańców od pięknej plaży, co sprawia, że Zdrojowa Park to idealne miejsce dla osób ceniących bliskość natury i komfort.</p>
                </div>
            </div>
        </div>
    )
};

export default Opis;