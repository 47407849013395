import React from 'react';
import SwinoujscieOpis from '../components/SwinoujscieOpis';
import zdjecie from './zdjecia/Zdrojowa_Park.jpg';

function Lokalizacja() {

  return (
    <div className='container-md mt-5' id='lokalizacja'>
      <div className='row'>
        <div className='col-12 mb-5'>
          <h2 className='mb-5'>LOKALIZACJA</h2>
          <iframe
            width='100%'
            height='500vw'
            src='https://www.openstreetmap.org/export/embed.html?bbox=14.248859882354738%2C53.91105023727104%2C14.284694194793701%2C53.92239891054234&amp;layer=mapnik&amp;marker=53.91672495946473%2C14.266777038574219&amp;zoom=10'
            title='Mapa OpenStreetMap'
            style={{ borderRadius: '20px' }}
          ></iframe>
        </div>
        <div className='mb-5 mt-3'>
        <SwinoujscieOpis />
        </div>
        <div className='container-md'>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-md-6 order-md-1'>
            <img src={zdjecie} alt='Zdrojowa z lotu ptaka' title= 'Zdrojowa Park z lotu ptaka' className='img-fluid mb-5' style={{
              width: '100%', 
              height: 'auto', 
              borderRadius: '20px', 
              objectFit: 'contain' }}
              ></img>
          </div>
          <div className='col-md-6 mb-5' id='opis_inwestycji'>
          <h3 className= 'mb-4'>Tuż przy plaży i w otoczeniu zieleni</h3>
            <p>Odkryj wyjątkowy komfort życia w inwestycji Zdrojowa Park, zlokalizowanej zaledwie 350 metrów od tętniącej życiem promenady i jedynie 450 metrów od jednej z najszerszych plaż nad Bałtykiem. To unikalne połączenie bliskości morza z zielonymi przestrzeniami otaczającego Parku Zdrojowego sprawia, że nasza inwestycja jest idealnym miejscem do wypoczynku, relaksu i codziennego życia w harmonii z naturą.</p>
            <p>Wybierz Zdrojowa Park i poczuj wyjątkową atmosferę nadmorskiej oazy, która oferuje więcej niż tylko bliskość plaży. To miejsce, które stanie się Twoim nowym domem i codzienną ucieczką od miejskiego zgiełku.</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Lokalizacja;
