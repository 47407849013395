import { FaPhoneAlt } from 'react-icons/fa';
import './Phoneicon.css'

function StickyIcon() {
    return (
        <a href='tel:+48604831476' className='sticky-icon' aria-label='kontakt'>
            <FaPhoneAlt size={30} />
        </a>
    );
}

export default StickyIcon;