import React from 'react';
import logo from './zdjecia/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Kontaktv2.css';

function Kontaktv2() {
    return (
        <div className="container-md mt-5 mb-5" id='kontakt'>
            <div className='row'>
                <div className="col-12 col-lg-6 mb-4">
                    <img 
                        src={logo} 
                        alt='logo' 
                        className='img-fluid mb-5' 
                        style={{ maxWidth: '55%', height: 'auto' }} 
                    />
                    <p>ARKADA - INWEST Sp. z o.o.</p>
                    <div className='row mt-4'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faMapPin} className='fa-2x' style={{ color: '#ffffff' }}/>
                        </div>
                        <div className='col-md-6'>
                            <p>ul. Gerarda Merkatora 7</p>
                            <p>70-676 Szczecin</p>
                        </div>
                    </div>
                    <div>
                        <h2 className="mb-4 mt-3">Kontakt</h2>
                        <p className='mb-5'>Zapraszamy Państwa do kontaktu za pośrednictwem poczty elektronicznej oraz telefonicznie.</p>
                    </div>
                    <div className='mt-5'>
                        <div className='col-md-6 mb-4'>
                            <p>Michał Grabowski</p>
                        </div>
                    <div className='row mt-3'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faPhone} className='fa-2x' style={{ color: '#ffffff'}}/>
                        </div>
                        <div className='col-md-6'>
                            <p>+48 604 831 476</p>
                        </div>
                    </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faEnvelope} className='fa-2x' style={{ color: '#ffffff' }}/>
                        </div>
                        <div className='col-md-6'>
                            <p>
                                <a href='mailto:grabowski.m@ciroko.com.pl' className='email-link'>grabowski.m@ciroko.com.pl</a>
                            </p>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <div className='col-md-6 mb-4'>
                            <p>Jakub Dynarski</p>
                        </div>
                    <div className='row mt-3'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faPhone} className='fa-2x' style={{ color: '#ffffff' }}/>
                        </div>
                        <div className='col-md-6'>
                            <p>+48 784 084 161</p>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faEnvelope} className='fa-2x' style={{ color: '#ffffff' }}/>
                        </div>
                        <div className='col-md-6'>
                            <p>
                                <a href='mailto:dynarski.j@ciroko.com.pl' className='email-link'>dynarski.j@ciroko.com.pl</a>
                            </p>
                        </div>
                    </div>
                    </div>
                    <div className='mt-5'>
                        <div className='col-md-6 mb-4'>
                            <p>Bernard Adamczyk</p>
                        </div>
                    <div className='row mt-3'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faPhone} className='fa-2x' style={{ color: '#ffffff' }}/>
                        </div>
                        <div className='col-md-6'>
                            <p>+48 604 831 760</p>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-1 mb-4 mb-md-0'>
                            <FontAwesomeIcon icon={faEnvelope} className='fa-2x' style={{ color: '#ffffff' }}/>
                        </div>
                        <div className='col-md-6'>
                            <p>
                                <a href='mailto:adamczyk.b@ciroko.com.pl' className='email-link'>adamczyk.b@ciroko.com.pl</a>
                            </p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex align-items-center">
                        <iframe
                            width='100%'
                            height='500vw'
                            src='https://www.openstreetmap.org/export/embed.html?bbox=14.58862066268921%2C53.402470783125416%2C14.597579240798952%2C53.40534268214261&amp;layer=mapnik&amp;marker=53.403906756866306%2C14.59309995174408,zoom=15'
                            title='Lokalizacja Ciroko'
                            style={{ border: 0 }}
                        ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Kontaktv2;
