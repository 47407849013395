import './Navbar.css';
import logo from './zdjecia/logo.png';

function Navbar() {

  return (
    <div id="navbar">
      <nav className='navbar navbar-expand-xl bg-black'>
        <div className="container-xxl">
          <img src={logo} alt="logo" className="img-fluid" id='logo' style={{ height: 'auto' }} />
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top-bar"></span>
            <span className='toggler-icon middle-bar'></span>
            <span className='toggler-icon bottom-bar'></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <div className="navbar-nav ms-auto text-end mt-3">
              <p>
                <a className='navbar-link me-3' href='#lokalizacja'>Lokalizacja</a>
              </p>
              <p>
                <a className='navbar-link me-3' href='#swinoujscie_opis'>Świnoujście - Perła Bałtyku</a>
              </p>
              <p>
                <a className='navbar-link me-3' href='#opis_inwestycji'>Opis inwestycji</a>
              </p>
              <p>
                <a className='navbar-link me-3' href='#atuty'>Udogodniena</a>
              </p>
              <p>
                <a className='navbar-link me-3' href='#typy_mieszkan'>Układy mieszkań</a>
              </p>
              <p>
                <a className='navbar-link me-3' href='#galeria_slider_duza'>Wizualizacje</a>
              </p>
              <p>
              <a className='navbar-link me-3' href='#kontakt'>Kontakt</a>
              </p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
