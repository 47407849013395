import './HeroParallax.css';
import logo  from './zdjecia/logo.png';


function HeroParallax () {
   return (
    <div className='d-none d-xl-block' id='hero_parallax'>
        <div className='container-md'>
        <div className='row align-items-center'>
        <div className='col-md-6 mb-5 d-flex align-items-start align-items-start'>
          <div className='navbar-brand'>
            <img src={logo} alt='logo Zdrojowa Park' className='img-fluid' style={{ 
              maxWidth: '70%', 
              height: 'auto',
              }}>
              </img>
          </div>
        </div>
        <div className='col-md-6 d-flex flex-column mt-3'>
          <p className='text-start'>Zamieszkaj w sercu Świnoujścia, tuż przy Parku Zdrojowym - miejscu, gdzie natura spotyka elegancję. Nowoczesne apartamenty stworzone z myślą o komforcie i harmonii, otoczone zielenią, oferują idealną przestrzeń do życia i relaksu.</p>
        </div>
        </div>
        </div>
        <div className='parallax'></div>
    </div>
   );
}

export default HeroParallax;