import React from 'react';
import logo from './zdjecia/logo.png';
import wizualizacja from './zdjecia/Zdrojowa_Park_lokalizacja.jpg';

function Hero() {
  return (
    <div className='hero-section d-xl-none'>
    <div className='container-fluid mt-5' id='hero'>
      <div className='row align-items-center'>
        <div className='col-md-6 mb-4 d-flex flex-column align-items-start align-items-start'>
          <div className='navbar-brand d-none d-md-block'>
            <img src={logo} alt='logo Zdrojowa Park' className='img-fluid' id='logo-sm' style={{ maxWidth: '70%', height: 'auto' }}></img>
          </div>
        </div>
        <div className='col-md-6 d-flex flex-column'>
          <p className='text-start'>Zamieszkaj w sercu Świnoujścia, tuż przy Parku Zdrojowym - miejscu, gdzie natura spotyka elegancję. Nowoczesne apartamenty stworzone z myślą o komforcie i harmonii, otoczone zielenią, oferują idealną przestrzeń do życia i relaksu.</p>
        </div>
        <div className='container-fluid' style={{
           width: '100%', // Ustawienie szerokości na pełną szerokość elementu nadrzędnego
           padding: 0,    // Usunięcie paddingu
           margin: 0,     // Usunięcie marginesów
           overflowX: 'hidden', // Ukrycie wszelkiego nadmiaru treści w osi X
        }}>
          <img src={wizualizacja} alt='zdrojowa park lokalizacja' className='img-fluid' style={{ width: '100%', height: 'auto' }}></img>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Hero;