import React from 'react';
import img1 from './zdjecia/m_25_do_30_m2.png';
import img2 from './zdjecia/m_30_do_32_m2.png';
import img3 from './zdjecia/m_1_sypialnia_35_do_44_m2-1.png';
import img4 from './zdjecia/m_2_sypialnia_47_do_52_m2-1.png';
import img5 from './zdjecia/m_3_sypialnia_59_do_62_m2-1.png';

function Typy_mieszkan () {
    return (
        <div className='container-md mt-5 mb-5' id='typy_mieszkan'>
            <div>
            <h2 className='mb-3'>UKŁADY MIESZKAŃ</h2>
            <p className='mb-5'>Poniżej przedstawiamy Państwu układy mieszkań dostępnych w naszej nowej inwestycji "Zdrojowa Park" w Świnoujściu.</p>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <a href={img1} target='_blank' rel='noopener noreferrer'>
                        <img src={img1} title='Mieszkanie ok. 25-30 m2' style={{ borderRadius: '20px' }} alt='Mieszkanie 1 pokojowe 25-30 m2' className='img-fluid'></img>
                    </a>
                    <h3 className='mt-3'>MIESZKANIE 1 POKOJOWE</h3>
                    <p className='mb-5'>Powierzchnie od ok. 25 do ok. 30 m2.</p>
                </div>
                <div className='col-md-4'>
                <a href={img2} target='_blank' rel='noopener noreferrer'>
                        <img src={img2} title='Mieszkanie ok. 32 m2' style={{ borderRadius: '20px' }} alt='Mieszkanie 1 pokojowe ok. 32 m2' className='img-fluid'></img>
                    </a>
                    <h3 className='mt-3'>MIESZKANIE 1 POKOJOWE</h3>
                    <p className='mb-5'>Mieszkanie 1 pokojowe o powierzchni ok. 32 m2.</p>
                </div>
                <div className='col-md-4'>
                <a href={img3} target='_blank' rel='noopener noreferrer'>
                        <img src={img3} title='Mieszkanie ok. 35-44 m2' style={{borderRadius: "20px"}} alt='Mieszkanie 2 pokojowe 35-44 m2' className='img-fluid'></img>
                    </a>
                    <h3 className='mt-3'>MIESZKANIE 2 POKOJOWE</h3>
                    <p className='mb-5'>Mieszkanie 2 pokojowe o powierzchni od 35 do 44 m2.</p>
                </div>
            </div>
            <div className='row d-fled justify-content-center'>
                <div className='col-md-4'>
                    <a href={img4} target='blank' rel='noopener noreferrer'>
                        <img src={img4} title='Mieszkanie ok. 47-52 m2' style={{ borderRadius: '20px' }} alt='Mieszkanie 3 pokojowe 47-52 m2' className='img-fluid'></img>
                    </a>
                    <h3 className='mt-3'>MIESZKANIE 3 POKOJOWE</h3>
                    <p className='mb-5'>Mieszkanie 3 pokojowe o powierzchni od 47 do 52 m2.</p>
                </div>
                <div className='col-md-4'>
                    <a href={img5} target='blank' rel='noopener noreferrer'>
                        <img src={img5} title='Mieszkanie ok. 59-62 m2' style={{ borderRadius: '20px' }} alt='Mieszkanie 4 pokojowe 59-62 m2' className='img-fluid'></img>
                    </a>
                    <h3 className='mt-3'>MIESZKANIE 4 POKOJOWE</h3>
                    <p className='mb-t'>Mieszkanie 4 pokojowe o powierzchni od 59 do 62 m2.</p>
                </div>
            </div>
        </div>
    )
};

export default Typy_mieszkan;