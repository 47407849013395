import React from 'react';
import { Carousel } from 'react-bootstrap';
import ikonka6 from './zdjecia/basen.png';
import ikonka7 from './zdjecia/morze.png';
import ikonka8 from './zdjecia/okolica.png';
import ikonka9 from './zdjecia/plaza.png';
import ikonka10 from './zdjecia/parking.png';
import './Atuty.css';

function Atuty() {
    return(

        <div className='container-md mt-5' id='atuty'>
            <h2 className='mb-3' id='atuty-heading'>UDOGODNIENIA</h2>

        <div className='container-md mb-5 mt-5 d-flex align-items-center justify-content-center' id='atuty-main' /*style={{
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        minHeight: '25vw',
        borderRadius: '20px',
        padding: '20px'}} */>


            <div className='row justify-content-center d-none d-xl-flex align-items-center'>
                <div className='col-md-2 text-center p-4 mb-3 ms-3 me-4' style={{backgroundColor: '#947436', borderRadius: '20px', height: '11rem', width: '10em'}}>
                    <img className='mb-3' src={ikonka9} alt='plaża' style={{width: '40%', height: 'auto'}}></img>
                    <p className='mt-3'>500m do plaży</p>
                </div>
                <div className='col-md-2 text-center p-4 mb-3 ms-3 me-4' style={{backgroundColor: '#947436', borderRadius: '20px', height: '11rem', width: '10em'}}>
                    <img className='mb-3' src={ikonka6} alt='basen' style={{width: '40%', height: 'auto'}}></img>
                    <p className='mt-3'>Basen hotelowy</p>
                </div>
                <div className='col-md-2 text-center p-4 mb-3 ms-3 me-4' style={{backgroundColor: '#947436', borderRadius: '20px', height: '11rem', width: '10em'}}>
                    <img className='mb-3' src={ikonka8} alt='okolica' style={{width: '40%', height: 'auto'}}></img>
                    <p className='mt-3'>Otoczenie zieleni</p>
                </div>
                <div className='col-md-2 text-center p-4 mb-3 ms-3 me-4' style={{backgroundColor: '#947436', borderRadius: '20px', height: '11rem', width: '10em'}}>
                    <img className='mb-3' src={ikonka10} alt='parking' style={{width: '40%', height: 'auto'}}></img>
                    <p className='mt-3'>Parking podziemny</p>
                </div>
                <div className='col-md-2 text-center p-3 mb-3 ms-3 me-4' style={{backgroundColor: '#947436', borderRadius: '20px', height: '11rem', width: '10em'}}>
                    <img className='mb-3' src={ikonka7} alt='dzielnica' style={{width: '40%', height: 'auto'}}></img>
                    <p className='mt-3'>Dzielnica nadmorska</p>
                </div>
            </div>

            <div className='d-xl-none p-2 carousel__indicators'>
                <Carousel id='galeria-atuty'>
                    <Carousel.Item>
                        <div className='text-center p-4' style={{ backgroundColor: '#947436', borderRadius: '20px',}}>
                            <img src={ikonka9} alt='plaża' style={{ width: '40%', height: 'auto'}}></img>
                            <p className='m-4'>500m do plaży</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-center p-4' style={{ backgroundColor: '#947436', borderRadius: '20px'}}>
                            <img src={ikonka6} alt='basen' style={{ width: '40%', height: 'auto'}}></img>
                            <p className='m-4'>Basen hotelowy</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-center p-4' style={{ backgroundColor: '#947436', borderRadius: '20px'}}>
                            <img src={ikonka8} alt='okolica' style={{ width: '40%', height: 'auto'}}></img>
                            <p className='m-4'>Otoczenie zieleni</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-center p-4' style={{ backgroundColor: '#947436', borderRadius: '20px'}}>
                            <img src={ikonka10} alt='parking' style={{ width: '40%', height: 'auto'}}></img>
                            <p className='m-4'>Parking podziemny</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='text-center p-4' style={{ backgroundColor: '#947436', borderRadius: '20px'}}>
                            <img src={ikonka7} alt='dzielnica' style={{ width: '40%', height: 'auto'}}></img>
                            <p className='m-4'>Dzielnica nadmorska</p>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
        </div>
    )
}

export default Atuty;