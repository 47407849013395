import { Helmet } from 'react-helmet';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Lokalizacja from './components/Lokalizacja';
import OpisInwestycji from './components/OpisInwestycji';
import TypyMieszkan from './components/TypyMieszkan';
import Footer from './components/Footer';
import Atuty from './components/Atuty';
import GallerySlider from './components/GallerySlider';
import StickyIcon from './components/StickyPhoneicon';
import Parallax from './components/HeroParallax';
import Kontaktv2 from './components/Kontaktv2';
import HasloReklamowe from './components/HasloReklamowe';

function App() {

  return (
    <div className="App" id='App'>
      <Helmet>
        <title>Zdrojowa Park</title>
        <meta name='description' content='Odkryj wyjątkowy komfort życia w inwestycji Zdrojowa Park, zlokalizowanej zaledwie 350 metrów od tętniącej życiem promenady i jedynie 450 metrów od jednej z najszerszych plaż nad Bałtykiem. Zamieszkaj w sercu Świnoujścia, tuż przy Parku Zdrojowym.'/>
        <meta name='keywords' content='Zdrojowa Park, Sprzedaż Apartamentów w Świnoujściu, Nowa inwestycja deweloperska, Nieruchomości w Świnoujściu, Luksusowe apartamenty, Mieszkania blisko plaży, Inwestycje nad morzem, Apartamenty nad morzem na sprzedaż, Nowoczesne apartamenty'></meta>
        <link rel='canonical' href='https://zdrojowa-park.pl'/>
      </Helmet>
      <Navbar />
      <Parallax />
      <Hero />
      <HasloReklamowe />
      <Lokalizacja />
      <OpisInwestycji />
      <Atuty />
      <TypyMieszkan />
      <GallerySlider />
      <Kontaktv2 />
      <StickyIcon />
      <Footer />
    </div>
  );
}

export default App;
