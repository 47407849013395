import React, { useState, useEffect } from 'react';
import './GallerySlider.css';
import img1 from './zdjecia/bryla_budynku_1.png';
import img2 from './zdjecia/bryla_budynku_2.png';
import img3 from './zdjecia/bryla_budynku_3.png';
import img4 from './zdjecia/bryla_budynku_4.png';
import img5 from './zdjecia/bryla_budynku_5.png';
import img6 from './zdjecia/bryla_budynku_6.png';
import img7 from'./zdjecia/bryla_budynku_7.png';
import img8 from './zdjecia/bryla_budynku_8.png';
import { Carousel } from 'react-bootstrap';


function Gallery_slider() {
  const images = [
    { src: img1, alt: 'Bryła budynku 1' },
    //*{ src: img2, alt: 'Bryła budynku 2' },*//
    { src: img3, alt: 'Bryła budynku 3' },
    //*{ src: img4, alt: 'Bryła budynku 4' },*//
    { src: img5, alt: 'Bryła budynku 5' },
    { src: img6, alt: 'Bryła budynku 6' },
    //*{ src: img7, alt: 'Bryła budynku 7' },*//
    { src: img8, alt: 'Bryła budynku 8' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="container-md" id='galeria_slider_duza'>
      <div className="row justify-content-center">
      <h2>WIZUALIZACJE</h2>
      <p className='mb-5'>W poniższej galerii przedstawiamy Państwu wstępne wizualizacje budynków.</p>
        <div className="col-md-12">
          <div className="carousel d-none d-md-block">
            <div
              className="carousel__images"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {images.map((image, index) => (
                <div
                  className={`carousel__image ${index === currentIndex ? 'active' : ''}`}
                  key={index}
                >
                  <img src={image.src} alt={image.alt} style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }} />
                </div>
              ))}
            </div>

            <button className="carousel__button carousel__button--prev" onClick={handlePrev}>
              {"<"}
            </button>
            <button className="carousel__button carousel__button--next" onClick={handleNext}>
              {">"}
            </button>

            <div className="carousel__indicators">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`carousel__indicator ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => setCurrentIndex(index)}
                ></button>
              ))}
            </div>
          </div>

          <div className='d-md-none mb-5' id='galeria_slider_mała'>
            <Carousel>
              <Carousel.Item>
                <div>
                  <img src={img1} alt='Bryła budynku 1' style={{ 
                    width: '100%', 
                    height: 'auto', 
                    maxHeight: '100%', 
                    objectFit: 'contain' 
                    }}>
                    </img>
                </div>
              </Carousel.Item>
              <Carousel.Item id='item2'>
                <img src={img2} alt='Bryła budynku 2' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                  }}>
                </img>
              </Carousel.Item>
              <Carousel.Item>
                <img src={img3} alt='Bryła budynku 3' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}>
                </img>
              </Carousel.Item>
              <Carousel.Item>
                <img src={img4} alt='Bryła budynku 4' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}>
                </img>
              </Carousel.Item>
              <Carousel.Item>
                <img src={img5} alt='Bryła budynku 5' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}>
                </img>
              </Carousel.Item>
              <Carousel.Item>
                <img src={img6} alt='Bryła budynku 6' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}>
                </img>
              </Carousel.Item>
              <Carousel.Item>
                <img src={img7} alt='Bryła budynku 7' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}>
                </img>
              </Carousel.Item>
              <Carousel.Item>
                <img src={img8} alt='Bryła budynku 8' style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}>
                </img>
              </Carousel.Item>
            </Carousel>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Gallery_slider;
